<template>
  <div>
    <b-breadcrumb class="breadcrumb-slash mb-1">
      <b-breadcrumb-item>
        จัดการลูกค้า
        <!-- Dashboard -->
      </b-breadcrumb-item>
      <b-breadcrumb-item active> รายการลูกค้า </b-breadcrumb-item>
    </b-breadcrumb>


    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label> แสดง </label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> รายการ </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="ค้นหา"
              />

            </div>
          </b-col>
        </b-row>
      </div>
      
      <b-table
        ref="refCustomerListTable"
        class="position-relative"
        :items="fetchCustomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="'ไม่พบข้อมูล'"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(order_fullname)="data">
          <b-link
              :to="{
                name: 'admin-customer-order',
                params: { order: data.item.order_mobile },
              }"
              class="font-weight-bold d-block"
              style="max-width: 35rem;"
            >
              {{ data.item.order_fullname }}
            </b-link>
        </template>

        <template #cell(index)="data">
          {{ (currentPage - 1) * perPage + data.index + 1 }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              แสดง
              {{ dataMeta.to }}
              จาก
              {{ dataMeta.of }}
              รายการ
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCustomers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// import CustomerListFilters from './CustomerListFilters.vue'
import useCustomerList from "./useCustomerList";
import customerStoreModule from "./customerStoreModule";


export default {
  components: {
    // CustomerListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
    vSelect,
  },
  setup() {
    // Register module
    if (!store.hasModule("customer-list"))
      store.registerModule("customer-list", customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("customer-list")) store.unregisterModule("customer-list");
    });

    // const titleOptions = [
    //   { label: 'Mr.', value: 'Mr.' },
    //   { label: 'Mrs.', value: 'Mrs.' },
    //   { label: 'Ms.', value: 'Ms.' },
    // ]

    const statusOptions = [
      { label: "Pending", value: "Pending" },
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" },
    ];

    const {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // UI
      resolveStatusVariant,

      // Extra Filters
      customerNameFilter,
      customerCategoryFilter,
      statusFilter,
    } = useCustomerList();

    return {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStatusVariant,

      // titleOptions,
      statusOptions,

      // Extra Filters
      customerNameFilter,
      customerCategoryFilter,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
